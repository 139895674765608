import React, { useCallback, useState } from "react";
import { ResponsiveBottomAlignedCenter } from "components/spinner/Center";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { brandDarkBlue } from "theme/colors";
import { IState } from "store/types";
import { Button, useTheme } from "@mui/material";
import { commonStyles } from "theme/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { flipPreviewDrawer } from "actions/previewDrawerState";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import { featureToggles } from "config/featureToggles";
import { WorkspacePopover } from "./workspace/WorkspacePopover";

const Background = styled.div`
  background-color: ${brandDarkBlue.toString()};
  width: 100%;
  display: flex;
  height: 176px;
  margin-bottom: 5px;
`;
const H1 = styled.h1`
  margin-top: 0rem;
  color: white;
`;
const H3 = styled.h3`
  color: white;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0rem;
  justify-content: space-between;
  align-items: center;
  // padding-right: 24px;
`;

const DontBreak = styled.span`
  display: inline-block;
`;

export const AppSubheading = () => {
  // TODO: get title based on type of component in url path
  // const type = getTypeFromUrlPath(window.location.pathname);
  // const dispatch = useDispatch();
  // const classes = useStyles();
  const theme = useTheme();
  const styles = commonStyles(theme);
  const capsObject = useSelector((state: IState) => state.capsObject);
  const title = capsObject?.name;
  const code = capsObject?.code && `| ${capsObject.code}`;
  const version = capsObject?.version && `| v${capsObject.version}`;

  const dispatch = useDispatch();
  const handleShowPreview = useCallback(() => dispatch(flipPreviewDrawer()), [dispatch]);
  const showPreview = useSelector((state: IState) => !state.previewDrawerState.open);

  /** preview workspace **/
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const openWorkspaceMenu = useCallback((event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);
  const closeWorkspaceMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>{capsObject === null ? "Rule Editor" : `${capsObject.code} - Rule Editor`}</title>
        </Helmet>
      </HelmetProvider>
      <Background>
        <ResponsiveBottomAlignedCenter>
          <Row>
            <H3>University of Melbourne</H3>
            <Button
              onClick={handleShowPreview}
              variant="outlined"
              size="small"
              color="inherit"
              sx={styles.headerButton}
              startIcon={
                showPreview ? (
                  <Visibility sx={styles.headerButtonIcon} />
                ) : (
                  <VisibilityOff sx={styles.headerButtonIcon} />
                )
              }
            >
              {showPreview ? "Preview" : "Close Preview"}
            </Button>
          </Row>
          <H1 style={{ display: "flex", alignItems: "center" }}>
            <DontBreak>
              {title} {code} {version}
            </DontBreak>
            {featureToggles.previewWorkspaces && (
              <div style={{ display: "flex", alignContent: "flex-end", marginLeft: "auto" }}>
                <Button
                  aria-controls="preview-workspace-menu"
                  aria-haspopup="true"
                  variant="outlined"
                  size="small"
                  color="inherit"
                  sx={styles.headerButton}
                  onClick={(e) => openWorkspaceMenu(e)}
                  startIcon={<WorkspacesIcon sx={styles.headerButtonIcon} />}
                  endIcon={
                    anchorEl === null ? (
                      <ExpandLessIcon sx={styles.headerButtonIcon} />
                    ) : (
                      <ExpandMoreIcon sx={styles.headerButtonIcon} />
                    )
                  }
                >
                  Workspaces
                </Button>
              </div>
            )}
          </H1>
        </ResponsiveBottomAlignedCenter>
      </Background>
      {anchorEl !== null && <WorkspacePopover anchorEl={anchorEl} onClose={closeWorkspaceMenu} />}
    </div>
  );
};
