import React, { useCallback, useEffect, useState } from "react";
import { Autocomplete, Grid, IconButton, Popover, TextField, Tooltip, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { isEmpty } from "lodash";
import { $delete, get, post } from "lib/fetch";
import { useSelector } from "react-redux";
import { IState } from "store/types";
import { commonStyles } from "theme/styles";
import { brandBlue } from "theme/colors";
import { LabelWithHint } from "components/help-hint/HelpHint";
import { getWorkspaceItemIndex, newWorkspaceItem } from "./workspaceUtils";
import { openSnackBar } from "actions/snackBar";
import { useDispatch } from "react-redux";

interface IProps {
  anchorEl: any;
  onClose: () => any;
}

export const WorkspacePopover = ({ anchorEl, onClose }: IProps) => {
  const dispatch = useDispatch();
  const capsObject = useSelector((state: IState) => state.capsObject);
  const [freeSolo, setFreeSolo] = useState("");
  const [allWorkspaces, setAllWorkspaces] = useState<any>([]);
  const [myWorkspace, setMyWorkspace] = useState<any>(null);
  const theme = useTheme();
  const commonStyling = commonStyles(theme);

  useEffect(() => {
    const getWorkspaces = async () => {
      try {
        const workspaces = await get("/v1/workspaces");
        setAllWorkspaces(workspaces);
      } catch (e) {
        console.log(e);
        setAllWorkspaces([]);
      }
    };
    getWorkspaces();
  }, []);

  const doClearWorkspace = useCallback(() => {
    setMyWorkspace(null);
    setFreeSolo("");
  }, []);

  const addWorkspace = useCallback(async () => {
    if (!isEmpty(freeSolo)) {
      try {
        const workspace = await post("/v1/workspaces", { name: freeSolo, owner: "unknown" });
        if (workspace.error) {
          dispatch(openSnackBar({ type: "error", message: workspace.error, autoHideDuration: 30000 }));
          return;
        }
        workspace.items.push({ id: null, ...newWorkspaceItem(capsObject) });
        setMyWorkspace(workspace);
        if (!allWorkspaces.find((f: any) => f.id === workspace.id)) {
          const newList = [...allWorkspaces];
          newList.unshift(workspace);
          setAllWorkspaces(newList);
        }
      } catch (e) {
        console.log(e);
        dispatch(openSnackBar({ type: "error", message: "Could not add workspace", autoHideDuration: 30000 }));
      }
    }
  }, [allWorkspaces, capsObject, dispatch, freeSolo]);

  const deleteWorkspace = useCallback(async () => {
    if (myWorkspace && myWorkspace.id !== null) {
      try {
        await $delete(`/v1/workspaces/${myWorkspace.id}`);
        setAllWorkspaces([...allWorkspaces].filter((w: any) => w.id !== myWorkspace.id));
        setFreeSolo("");
        setMyWorkspace(null);
      } catch (e) {
        console.log(e);
        dispatch(openSnackBar({ type: "error", message: "Could not delete workspace", autoHideDuration: 30000 }));
      }
    }
  }, [allWorkspaces, dispatch, myWorkspace]);

  const setWorkspace = useCallback(
    (workspace: any) => {
      if (workspace !== null) {
        const item = newWorkspaceItem(capsObject);
        const idx = getWorkspaceItemIndex(item, workspace.items);
        const newWorkspace = { ...workspace };
        if (idx === -1) {
          newWorkspace.items.unshift(item);
        }
        setMyWorkspace(newWorkspace);
      } else {
        setMyWorkspace(null);
      }
    },
    [capsObject],
  );

  const addWorkspaceItem = useCallback(async () => {
    if (myWorkspace && !isEmpty(myWorkspace.id)) {
      try {
        const payload = newWorkspaceItem(capsObject);
        const workspace = await post(`/v1/workspaces/${myWorkspace.id}/item`, payload);
        setMyWorkspace(workspace);
        setAllWorkspaces([...allWorkspaces].map((ws: any) => (ws.id === workspace.id ? workspace : ws)));
      } catch (e) {
        console.log(e);
        dispatch(openSnackBar({ type: "error", message: "Could not add item to workspace", autoHideDuration: 30000 }));
      }
    }
  }, [allWorkspaces, capsObject, dispatch, myWorkspace]);

  const deleteWorkspaceItem = useCallback(
    async (id: string) => {
      if (myWorkspace && !isEmpty(myWorkspace.id)) {
        try {
          const workspace = await $delete(`/v1/workspaces/${myWorkspace.id}/item/${id}`);
          // re-add this current item if not already in the list
          const item = newWorkspaceItem(capsObject);
          const idx = getWorkspaceItemIndex(item, workspace.items);
          if (idx === -1) {
            workspace.items.unshift(item);
            setAllWorkspaces([...allWorkspaces].map((ws: any) => (ws.id === workspace.id ? workspace : ws)));
          }
          setMyWorkspace(workspace);
        } catch (e) {
          console.log(e);
          dispatch(
            openSnackBar({ type: "error", message: "Could not remove item from workspace", autoHideDuration: 30000 }),
          );
        }
      }
    },
    [myWorkspace, capsObject, allWorkspaces, dispatch],
  );

  return (
    <Popover
      anchorEl={anchorEl}
      open={true}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      sx={{ marginTop: "4px" }}
    >
      <div style={{ padding: "2px 20px 2px 20px", width: "480px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>
            <LabelWithHint
              label="Workspaces"
              helpText="You can either create a new workspace or use an existing one to add this item, allowing you to test its behavior in CPT. When testing, ensure that you reference the same workspace in CPT. Only one version of the item can be added to workspace at a time."
            />
          </h2>
          <IconButton onClick={onClose} style={{ paddingTop: "0px" }}>
            <CloseIcon />
          </IconButton>
        </div>
        <Grid container spacing={1} alignContent={"start"}>
          <Grid item xs={10} style={{ display: "flex", alignItems: "flex-start", paddingLeft: 0 }}>
            <Autocomplete
              freeSolo
              size="small"
              id="my-workspace"
              value={myWorkspace}
              inputValue={freeSolo}
              getOptionLabel={(option) => option.name}
              onInputChange={(_event, newValue: any) => {
                setFreeSolo(newValue);
                newValue === "" && doClearWorkspace();
              }}
              options={allWorkspaces}
              onChange={(_event, newValue: any) => {
                if (typeof newValue !== "string") {
                  setWorkspace(newValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  InputProps={params.InputProps}
                  inputProps={params.inputProps}
                  placeholder={isEmpty(myWorkspace) ? "Select or create new workspace..." : ""}
                />
              )}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={1} alignContent="center">
            <Tooltip title="Add workspace" placement="top-start">
              <span>
                <IconButton onClick={() => addWorkspace()} disabled={isEmpty(freeSolo)}>
                  <AddCircleIcon sx={{ fill: brandBlue.toString() }} />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs={1} alignContent="center">
            <Tooltip title="Delete workspace" placement="top-start">
              <span>
                <IconButton
                  onClick={() => deleteWorkspace()}
                  disabled={!myWorkspace || (myWorkspace && myWorkspace.id === null)}
                >
                  <RemoveCircleIcon sx={{ fill: brandBlue.toString() }} />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
        &nbsp;
        {!isEmpty(myWorkspace) && <hr style={{ margin: 0 }} />}
        {!isEmpty(myWorkspace) &&
          myWorkspace.items.map((item: any) => (
            <Grid
              container
              spacing={1}
              alignContent="start"
              key={item.id || "available"}
              style={{ margin: 0, padding: 0 }}
            >
              <Grid item xs={11} style={{ display: "flex", alignItems: "flex-start", padding: 0 }}>
                <p style={{ ...commonStyling.label, margin: 0, paddingTop: 10 }}>
                  {item.name} | {item.code} | v{item.version}
                </p>
              </Grid>
              <Grid item xs={1} alignContent="center" style={{ paddingTop: 0 }}>
                {isEmpty(item.id) || item.id === null ? (
                  <Tooltip title="Add item to workspace" placement="top-start">
                    <IconButton onClick={() => addWorkspaceItem()}>
                      <AddCircleIcon sx={{ fill: brandBlue.toString() }} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Remove item from workspace" placement="top-start">
                    <IconButton onClick={() => deleteWorkspaceItem(item.id)}>
                      <RemoveCircleIcon sx={{ fill: brandBlue.toString() }} />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          ))}
      </div>
    </Popover>
  );
};
