import { IWorkspaceItem } from "store/types";

export const newWorkspaceItem = (capsObject: any) => {
  return {
    name: capsObject?.name,
    code: capsObject?.code,
    type: capsObject?.rules.type,
    recordId: capsObject?.recordId,
    version: capsObject?.version,
    year: capsObject?.effectiveYear,
    rules: capsObject?.rules,
  };
};

export const getWorkspaceItemIndex = (item: IWorkspaceItem, items: IWorkspaceItem[]): number => {
  for (let j = 0; j < items.length; j++) {
    const i = items[j];
    if (
      i.name === item.name &&
      i.code === item.code &&
      i.type === item.type &&
      i.recordId === item.recordId &&
      //i.version === item.version && // only allow one version per recordId per year
      i.year === item.year
    ) {
      return j;
    }
  }
  return -1;
};
